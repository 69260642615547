<template>
  <div>
    <h4>
      Rive
    </h4>
    <hr>
    <div class="d-flex justify-content-center gap-12 pb-2">
      <div class="d-flex flex-column gap-12">
        <div class="d-flex align-center gap-12">
          BG : <input type="color" v-model="canvasBg1">
          <b-button
              v-if="false"
              variant="outline-success"
              size="sm"
              @click="startRive(rive1)">
            Play
          </b-button>
        </div>
        <canvas ref="canvas" width="450" height="800" :style="{'background-color': canvasBg1}"></canvas>
      </div>
      <div class="d-flex flex-column gap-12">
        <div>
          BG : <input type="color" v-model="canvasBg2">
        </div>
        <canvas ref="canvas2" width="450" height="800" :style="{'background-color': canvasBg2}"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Rive } from "@rive-app/webgl";
import { BButton } from 'bootstrap-vue'

export default {
  name: "Rive",
  props: {
    msg: String,
  },
  components: {
    BButton,
  },
  data() {
    return {
      canvasBg1: '#000000',
      canvasBg2: '#b4acac',
      rive1: null,
      rive2: null,
    };
  },
  mounted() {
    console.log(this.$route)
    const fileName = this.$route.query && this.$route.query.file ? this.$route.query.file : null
    const fileName2 = this.$route.query && this.$route.query.file2 ? this.$route.query.file2 : null

    console.log('name', name)

    if (fileName) {
      this.rive1 = new Rive({
        canvas: this.$refs.canvas,
        src: `../../assets/animations/${fileName}`,
        autoplay: true,
        onLoop: () => {
          console.log('loop')
        },
        onStateChange: () => {
          console.log('onStateChange')
        }
      });
    }

    if (fileName2) {
      this.rive2 = new Rive({
        canvas: this.$refs.canvas2,
        src: `../../assets/animations/${fileName2}`,
        autoplay: true,
        onLoop: () => {
          console.log('loop 2')
        },
        onStateChange: () => {
          console.log('onStateChange 2')
        }
      });
    }
  },
  methods: {
    startRive(rive) {
      if (rive && !rive.isPlaying) {
        console.log('play', rive)
        rive.play({autoplay: true})
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
canvas {
  border: 1px solid #3D3D3D;
  padding: 8px;
}
</style>